import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import Riser from '@/components/riser-section';

import PageBanner from '@/components/blog-hub-page-banner';
import WhatsGoingOn from '@/components/whats-going-on-section';
import LatestBlogs from '@/components/latest-blogs-section';
import { blogsData } from '@/utils/dummy-data/latest-blogs-data';
import { graphql, PageProps } from 'gatsby';
import { getTitle } from '@/utils/helper/getTitle';
import { IBlog } from '@/utils/interface';

const BlogPage = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbs={crumbs}>
      <Seo
        title={data?.sanityBlogPageMetadata?.blog_page_metadata?.pageMetaName || getTitle(data?.sanityBlogHubBanner)}
        description={data?.sanityBlogPageMetadata?.blog_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityBlogPageMetadata?.blog_page_metadata?.metadata}
        canonical={data?.sanityBlogPageMetadata?.blog_page_metadata?.canonical}
      />
      <PageBanner
        title={getTitle(data?.sanityBlogHubBanner)}
        imageUrl={data?.sanityBlogHubBanner?.banner_image}
        page="BLOG_HUB"
      />
      <WhatsGoingOn
        title={data?.sanityBlogHubWhatsGoingOn?.title}
        videoUrl={data?.sanityBlogHubWhatsGoingOn?.featured_blog_video}
        videoTitle={data?.sanityBlogHubWhatsGoingOn?.video_title}
        thumbnail={data?.sanityBlogHubWhatsGoingOn?.featured_blog?.thumbnail}
        videoSubTitle={data?.sanityBlogHubWhatsGoingOn?.video_tag}
        description={data?.sanityBlogHubWhatsGoingOn?._rawVideoDescription}
        events={data?.featuredBlog?.edges}
      />
      <LatestBlogs
        title={blogsData.title}
        blogs={data?.allSanityBlog?.edges}
        // filterData={data?.allSanityBlogCategories?.edges}
        filterData={data?.allSanityBlogCategories?.edges || []}
        pageInfo={data?.allSanityBlog?.pageInfo}
      />

      <Riser
        title={data?.sanityBlogHubRiser?.title}
        textcontent={data?.sanityBlogHubRiser?.description}
        actionButton={data?.sanityBlogHubRiser?.button}
      />
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    sanityBlogPageMetadata {
      blog_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityBlogHubBanner {
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      banner_image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
      }
    }
    sanityBlogHubRiser {
      description
      title
      button {
        url
        title
      }
    }
    allSanityBlog(sort: { fields: _createdAt, order: DESC }, skip: $skip, limit: $limit) {
      pageInfo {
        totalCount
        perPage
        pageCount
        itemCount
        hasPreviousPage
        hasNextPage
        currentPage
      }
      edges {
        node {
          id
          featured
          slug {
            current
          }
          excerpt
          title
          category {
            id
            title
          }
          clap_count
          like_count
          love_count
          author {
            name

            photo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            social_media {
              youtube
              twitter
              tiktok
              linkedin
              instagram
              facebook
            }
            position
          }
          thumbnail {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    featuredBlog: allSanityBlog(
      sort: { fields: _createdAt, order: DESC }
      filter: { featured: { eq: true } }
    ) {
      pageInfo {
        totalCount
        perPage
        pageCount
        itemCount
        hasPreviousPage
        hasNextPage
        currentPage
      }
      edges {
        node {
          id
          featured
          slug {
            current
          }
          excerpt
          title
          category {
            id
            title
          }
          clap_count
          like_count
          love_count
          author {
            name
            photo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            social_media {
              youtube
              twitter
              tiktok
              linkedin
              instagram
              facebook
            }
            position
          }
          thumbnail {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allSanityBlogCategories {
      edges {
        node {
          id
          title
        }
      }
    }
    sanityBlogHubWhatsGoingOn {
      id
      title
      featured_blog_video
      video_tag
      video_title
      _rawVideoDescription
    }
  }
`;
